<template>
  <div
    :key="$route.fullPath"
    ref="profileDeatils"
    v-scroll="handleScroll"
    class="profile-details"
    :class="{ 'dialog-box': dialogStatus }"
    style="height: 100%; overflow-y: auto"
  >
    <template v-if="!loading">
      <div class="oce-hcp-div"></div>
      <div class="top" :class="{ shrinked: shrinked }">
        <suggestion
          :hcp-data="hcpData"
          :show-product="showProduct"
          :class="{ shrinked: shrinked }"
          @refreshFn="getDetailFn"
        />
      </div>
      <!-- show-slider -->
      <!-- v-model="selectedLabelDefault" -->
      <cube-tab-bar
        v-model="selectedLabelDefault"
        :data="tabs"
        class="top-tab box"
        style="z-index: 5"
        :class="{ shrinked: shrinked }"
        @click="clickHandler"
      >
        <cube-tab v-for="item in tabs" :key="item.label" :label="item.label">{{
          item.text
        }}</cube-tab>
      </cube-tab-bar>
      <div class="bottom" :class="{ shrinked: shrinked }">
        <div v-if="selectedLabelDefault === 'persona'" class="hcp body-content">
          <persona :data="hcpData" :show-product="showProduct" />
        </div>
        <div
          v-if="selectedLabelDefault === 'latestNews'"
          class="hcp body-content"
        >
          <latestNews :hcp-data="hcpData" :show-product="showProduct" :show-insight="false" />
        </div>
        <div v-if="selectedLabelDefault === 'activity'" class="activity">
          <activity ref="activity" />
        </div>
        <div
          v-if="selectedLabelDefault === 'perception'"
          class="activity body-content"
        >
          <perception :data="hcpData" />
        </div>
        <!-- <div v-if="selectedLabelDefault === 'online'" class="activity">
          <onlineTab ref="onlineTab" />
        </div>-->
        <div style="height: 70px"></div>
      </div>
    </template>
    <Loading v-if="loading" :loading="loading" />
    <div
      v-if="
        showTopBtn &&
          (selectedLabelDefault == 'activity' ||
            selectedLabelDefault == 'persona')
      "
      class="fixed-up"
      @click="backTopFn"
    >
      <font-awesome-icon
        class="icon question-icon"
        :icon="['fa', 'arrow-up']"
      />
    </div>
  </div>
</template>

<script>
import persona from "./components/persona";
import perception from "./components/perception";
import activity from "./components/activity";
import latestNews from '../kol/components/latestNews';
// import onlineTab from './components/onlineTab';
import suggestion from "./components/suggestion";
import * as py from "@/api/Physicians";
import * as help from "@/api/help";
import { getToken } from "@/utils/auth";
import authVerification from "@/mixins/authVerification";
import refreshUserID from "@/mixins/refreshUserID";
import { setLocalStorage, getLocalStorage } from "@/utils/index";

export default {
  name: "ProfileDetails",
  components: {
    persona,
    perception,
    activity,
    latestNews,
    // onlineTab,
    suggestion,
  },
  mixins: [authVerification, refreshUserID],
  data() {
    return {
      pageName: 'doctorDetail',
      whereLog: "ProfileDetails",
      selectedLabelDefault: "persona",
      tabs: [
        {
          text: this.$t("persona"),
          label: "persona",
        },
        {
          text: '最新动态',
          label: 'latestNews',
        },
        {
          text: this.$t("activity"),
          label: "activity",
        },
        {
          text: "策略地图", // this.$t('perception'),
          label: "perception",
        },
        // {
        //   text: '线上动态',
        //   label: 'online'
        // }
      ],
      hcpData: {}, // 医生信息
      academicCount: 1,
      shrinked: false,
      showTopBtn: false,
      showProduct: [],
    };
  },
  computed: {
    dialogStatus() {
      return this.$store.getters.dialogStatus;
    },
  },
  watch: {
    '$route.query': {
      handler(newQuery, oldQuery) {
        if (oldQuery) {
          if (newQuery.physician_id !== oldQuery.physician_id) {
            this.$forceUpdate(); // Force component to update
            this.getDetailFn();
            this.getUserId();
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    this.selectedLabelDefault = this.$route.query.selectedLabel ? this.$route.query.selectedLabel : "persona";
    this.getDetailFn();
    this.getUserId();
  },
  mounted() {},
  methods: {
    getShowProduct(user_id) {
      let is_sales = getLocalStorage('is_sales');
      let params = {
        user_id: user_id,
        physician_id: this.$route.query.physician_id,
      };
      if (is_sales === '1') {
        py.getUserPhysicianProduct(params).then((res) => {
          this.showProduct = [];
          if (res.value && res.value.length) {
            this.showProduct = res.value;
          }
        });
      } else {
        Promise.all([
          py.getUserProduct(getLocalStorage("user_id")),
          py.getProductPhysicians(this.$route.query.physician_id),
        ]).then((res) => {
          this.showProduct = [];
          res[0].value.map((item) => {
            res[1].value.map((el) => {
              if (item.product_id === el.product_id) {
                this.showProduct.push({
                  product_id: el.product_id,
                  product_name: el.product_name
                });
              }
            });
          });
        });
        // py.getUserProduct(getLocalStorage("user_id")).then((res) => {
        //   this.showProduct = [];
        //   if (res.value && res.value.length) {
        //     this.showProduct = res.value;
        //   }
        // });
      }
    },
    getTerritorieInfo(userId) {
      let is_sales = getLocalStorage('is_sales');
      if (is_sales === '1') { // 销售
        py.getTerritories(userId).then((res) => {
          if (res && res.value.length && res.value[0].territory) {
            setLocalStorage("territory_id", res.value[0].territory.territory_id);
            setLocalStorage("territory_type", res.value[0].territory.territory_type);
            // setLocalStorage("user_name", res.value[0].territory.user_name);
            this.getShowProduct(userId);
          } else {
            this.$router.push({
              path: "/error",
              query: {},
            });
          }
        });
        // py.getTerritories(userId).then((res) => {
        //   if (res && res.value.length) {
        //     setLocalStorage("territory_id", res.value[0].territory_id);
        //     setLocalStorage("territory_type", res.value[0].territory_type);
        //     setLocalStorage("user_name", res.value[0].user_name);
        //     this.getShowProduct(res.value[0].territory_id);
        //   } else {
        //     this.$router.push({
        //       path: "/error",
        //       query: {},
        //     });
        //   }
        // });
      } else { // 非销售
        this.getShowProduct('');
      }
    },
    clickHandler(label) {
      this.$refs.profileDeatils.scrollTo(0, 0, 0, "");
      // let tObj = this.tabs.find(ele => ele.label === label);
      // this.$store.dispatch('userSystemLog', {
      //   page_param: this.hcpData.physician_name,
      //   page_uri: this.$route.path,
      //   page: this.$t('persona') || tObj.text
      // });
      // this.$store.dispatch('settingActiveLabel', label);
      this.$router.push({
        path: '/hcp/detail',
        query: {
          physician_id: this.$route.query.physician_id,
          selectedLabel: label
        }
      });
    },
    handleScroll(evt, el) {
      this.shrinked = el.scrollTo > 20;
      this.showTopBtn = el.scrollTop > 500;
    },
    backTopFn() {
      this.$refs.profileDeatils.scrollTo(0, 0, 0, "");
    },
    getDetailFn() {
      // this.$route.query.selectedLabel ? this.$route.query.selectedLabel : 'persona';
      // let user_id = getLocalStorage("user_id");
      py.getPhysicians(this.$route.query.physician_id).then((res) => {
        if (res && res.value.length) {
          this.hcpData = res.value[0];
          setLocalStorage("institution_id", res.value[0].institution_id);
          this.$store.dispatch("setDoctorData", this.hcpData);

          // Pre-load is_favorite
          py.getUserFavoritePhysician(getLocalStorage("user_id"), this.$route.query.physician_id).then((res) => {
            if (res.value && res.value.length) {
              this.hcpData.is_favorite = res.value[0].is_favorite;
            } else {
              this.hcpData.is_favorite = 0;
            }
          });
        }
      });
      // py.getStarPhysiciansDetail(this.$route.query.physician_id, user_id).then(
      //   (res) => {
      //     res.value[0].physician.is_favorite = res.value[0].is_favorite
      //       ? res.value[0].is_favorite
      //       : 0;
      //     res.value[0].physician.user_id = res.value[0].user_id;
      //     res.value[0].physician.user_name = res.value[0].user_name;
      //     res.value[0].physician.user_favorite_physician_id =
      //       res.value[0].user_favorite_physician_id;
      //     res.value[0].physician.perception_type =
      //       res.value[0].physician_type || "";
      //     res.value[0].physician.influence_level =
      //       res.value[0].influence_level || "";
      //     this.hcpData = res.value[0].physician;
      //     this.$store.dispatch("setDoctorData", this.hcpData);
      //   }
      // );
    },
  },
};
</script>
<style lang="scss" scoped>
.box {
  border-top: none;
}
.profile-details ::v-deep .cube-pullup-wrapper {
  .before-trigger {
    font-size: 13px;
    padding: 15px 0;
    color: #dddddd;
  }
}
.fixed-up {
  font-size: 20px;
  padding: 8px 10px;
  border-radius: 50%;
  color: #2961a7;
  position: fixed;
  right: 20px;
  bottom: 30px;
  border: 1px solid #dddddd;
  background-color: #ffffff;
}
</style>
