import { render, staticRenderFns } from "./Brintellix.vue?vue&type=template&id=7a9e4e1d&scoped=true"
import script from "./Brintellix.vue?vue&type=script&lang=js"
export * from "./Brintellix.vue?vue&type=script&lang=js"
import style0 from "./Brintellix.vue?vue&type=style&index=0&id=7a9e4e1d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a9e4e1d",
  null
  
)

export default component.exports