<template>
  <van-loading v-if="loading" class="loading">加载中...</van-loading>
  <div v-else class="parting">
    <div>
      <CommonHeader
        title="医生分型"
        icon-url="/lundbeck/NBA/physician.png"
        :no-feedback="true"
      >
        <div slot="before">
          <img
            v-if="!isPhysicianFirst && canEditStatus"
            src="@/assets/lundbeck/NBA/edit.png"
            alt=""
            width="16"
            style="margin-top: 6px"
            @click="openPhysicianDialog()"
          />
        </div>
      </CommonHeader>
      <div v-if="isPhysicianFirst">
        <p class="tip-text">请先回答问题来确认医生分型</p>
        <Button
          v-if="canEditStatus"
          class="tip-button"
          round
          type="info"
          @click="openPhysicianDialog()"
        >
          立即进行问答
        </Button>
      </div>
      <div v-else>
        <div>
          <div class="parting-type">
            <div
              :class="
                physicianSelectedParting === 'D'
                  ? 'parting-type-selected'
                  : 'parting-type-unselected'
              "
            >
              <div @click="openPartingState('D')">
                <div v-if="physicianSelectedParting === 'D'">
                  <img :src="star" alt="" width="14" />
                </div>
                <p>症状控制 - 初识</p>
              </div>
            </div>
            <div
              :class="
                physicianSelectedParting === 'C'
                  ? 'parting-type-selected'
                  : 'parting-type-unselected'
              "
            >
              <div @click="openPartingState('C')">
                <div v-if="physicianSelectedParting === 'C'">
                  <img :src="star" alt="" width="14" />
                </div>
                <p>长期获益 - 尝试</p>
              </div>
            </div>
            <div
              :class="
                physicianSelectedParting === 'B'
                  ? 'parting-type-selected'
                  : 'parting-type-unselected'
              "
            >
              <div @click="openPartingState('B')">
                <div v-if="physicianSelectedParting === 'B'">
                  <img :src="star" alt="" width="14" />
                </div>
                <p>长期获益 - 认可</p>
              </div>
            </div>
            <div
              :class="
                physicianSelectedParting === 'A'
                  ? 'parting-type-selected'
                  : 'parting-type-unselected'
              "
            >
              <div @click="openPartingState('A')">
                <div v-if="physicianSelectedParting === 'A'">
                  <img :src="star" alt="" width="14" />
                </div>
                <p>长期获益 - 倡导</p>
              </div>
            </div>
          </div>
          <img src="@/assets/svg/xAxis.svg" alt="" style="width: 100%;" />
          <div class="parting-type-x">
            <p>低</p>
            <p>医生对于长期获益的认可度</p>
            <p>高</p>
          </div>
        </div>
        <div v-if="false" class="parting-type-history">
          <div class="history-title">
            <div class="title-flag"></div>
            <span class="title-text">分型历史</span>
          </div>
          <div class="history-content">
            <div
              v-for="item in showPhysicianPartingHistorys"
              :key="item.physician_typing_physician_id"
            >
              <div class="history-date">
                <div class="date-flag"></div>
                <span class="date-text">{{ getDate(item.create_time) }}</span>
              </div>
              <div class="history-text">
                <p>{{ item.result }}</p>
                <p class="modified-name">
                  {{ item.modify_user_name ?item.modify_user_name:item.modify_user_id }}
                </p>
              </div>
            </div>
            <div v-if="showExpand">
              <div
                v-if="!physicianHistoryExpandStatus"
                class="expend-btn"
                @click="expendPhysicianHistory"
              >
                <span class="arrow-down-flag"></span>
                展开
              </div>
              <div v-else class="expend-btn" @click="expendPhysicianHistory">
                <span class="arrow-up-flag"></span>
                收起
              </div>
            </div>
          </div>
        </div>
        <div class="parting-type-action">
          <CommonHeader
            title="下一步行动建议"
            icon-url="/lundbeck/NBA/light-bulb.png"
            :no-feedback="true"
          >
            <div v-if="!isPhysicianFirst && canEditStatus" slot="before">
              <van-popover
                v-model="nbaRejectDialogShow"
                placement="left"
                arrow-point-at-center
                :overlay="true"
              >
                <template #reference>
                  <img
                    src="@/assets/lundbeck/NBA/reject.png"
                    alt=""
                    width="28"
                    style="margin-top: 4px"
                    @click="openRejectDialog()"
                  />
                </template>
                <div class="reject-box">
                  <div class="reject-prompt">请选择不接受该建议的理由：</div>
                  <div>
                    <van-radio-group
                      v-model="rejectReasonTemp"
                      direction="horizontal"
                      class="answer"
                    >
                      <van-radio name="segmentation-changed" style="width: 100%;">观念分型发生变化</van-radio>
                      <van-radio name="key-message-not-matching" style="width: 100%;">关键信息不匹配</van-radio>
                      <van-radio name="ask-for-coaching" style="width: 100%;">无法进展，需要地区经理协访</van-radio>
                      <van-radio name="other-reasons" style="width: 100%;">其他原因</van-radio>
                    </van-radio-group>
                    <div v-if="rejectReasonTemp === 'other-reasons'">
                      <textarea v-model="rejectReasonOther" class="textarea"></textarea>
                    </div>
                  </div>
                  <div v-if="rejectReasonInvalid" style="font-size: 15px; margin-top: 5px; color: #cc4b47;">
                    请选择不接受该建议的理由
                  </div>
                  <div>
                    <Button
                      class="reject-button"
                      round
                      type="danger"
                      @click="rejectCurrentNba()"
                    >
                      拒绝该建议
                    </Button>
                  </div>
                </div>
              </van-popover>
            </div>
          </CommonHeader>
          <div>
            <div class="action-plan">
              <div v-if="physicianSelectedParting === 'A'">
                <div class="action-title">
                  <div class="title-flag"></div>
                  <span class="title-text">推进方向</span>
                </div>
                <div class="action-plan">
                  <div>
                    <p class="action-highlight">
                      <span>早发患者首选安齐来</span>
                    </p>
                  </div>
                </div>

                <div class="action-title">
                  <div class="title-flag"></div>
                  <span class="title-text">差异化竞争</span>
                </div>
                <div class="action-plan">
                  <div>
                    <p class="action-highlight">
                      <span>建立初始治疗新选择</span>
                    </p>
                  </div>
                </div>
                <div class="action-title">
                  <div class="title-flag"></div>
                  <span class="title-text">观念强化</span>
                </div>
                <div class="action-plan">
                  <p class="action-text">
                    <span class="action-flag"></span>
                    长期获益的重要性（神经保护）
                  </p>
                  <p class="action-text">
                    <span class="action-flag"></span>
                    安齐来可提供长期获益
                  </p>
                  <p class="action-text">
                    <span class="action-flag"></span>
                    疗效与安全性的平衡
                  </p>
                </div>
                <div v-if="false" class="proof-link">
                  <h4>相关资料</h4>
                  <p class="link">
                    <span class="action-flag"></span>
                    <a
                      @click="
                        lookDetail(
                          'LFile/nba_content/2020中国帕金森病治疗指南（第四版）更新解读-美化版2-0608-Final_1663939546000.pdf'
                        )
                      "
                    >
                      2020中国帕金森病治疗指南（第四版）更新解读-美化版2-0608-Final
                      (v1.0)
                    </a>
                  </p>
                  <p class="link">
                    <span class="action-flag"></span>
                    <a
                      @click="
                        lookDetail(
                          'LFile/nba_content/质控项目-帕金森病全程管理新进展_1663939841000.pdf'
                        )
                      "
                    >
                      质控项目-帕金森病全程管理新进展 (v1.0)
                    </a>
                  </p>
                  <p class="link">
                    <span class="action-flag"></span>
                    <a
                      @click="
                        lookDetail(
                          'LFile/nba_content/4-从指南更新看帕金森病的全程管理--MAO-BI应用的长期获益-0623 2022-8-26 103812 1_1663939134000.pdf'
                        )
                      "
                    >
                      4-从指南更新看帕金森病的全程管理—MAO-BI应用的长期获益-CNCT-001833_有效期至2023年7月
                      (v1.0)
                    </a>
                  </p>
                  <p class="link">
                    <span class="action-flag"></span>
                    <a
                      @click="
                        lookDetail(
                          'LFile/nba_content/CMA-5. PD经典研究：从结果回溯研究设计-V1.0-20220526_1663939753000.pdf'
                        )
                      "
                    >
                      CMA-5. PD经典研究：从结果回溯研究设计-V1.0-20220526 (v1.0)
                    </a>
                  </p>
                </div>
              </div>
              <div v-if="physicianSelectedParting === 'B'">
                <div class="action-title">
                  <div class="title-flag"></div>
                  <span class="title-text">推进方向</span>
                </div>
                <div class="action-plan">
                  <div>
                    <p class="action-highlight">
                      <span>LD首选添加安齐来</span>
                    </p>
                  </div>
                </div>

                <div class="action-title">
                  <div class="title-flag"></div>
                  <span class="title-text">差异化竞争</span>
                </div>
                <div class="action-plan">
                  <div>
                    <p class="action-highlight">
                      <span>区隔咪多吡</span>
                    </p>
                  </div>
                </div>
                <div class="action-title">
                  <div class="title-flag"></div>
                  <span class="title-text">观念强化</span>
                </div>
                <div class="action-plan">
                  <p class="action-text">
                    <span class="action-flag"></span>
                    长期获益的重要性（神经保护）
                  </p>
                  <p class="action-text">
                    <span class="action-flag"></span>
                    疗效与安全性全面占优
                  </p>
                  <div v-if="false" class="proof-link">
                    <h4>相关资料</h4>
                    <p class="link">
                      <span class="action-flag"></span>
                      <a
                        @click="
                          lookDetail(
                            'LFile/nba_content/2020中国帕金森病治疗指南（第四版）更新解读-美化版2-0608-Final_1663939546000.pdf'
                          )
                        "
                      >
                        2020中国帕金森病治疗指南（第四版）更新解读-美化版2-0608-Final
                        (v1.0)
                      </a>
                    </p>
                    <p class="link">
                      <span class="action-flag"></span>
                      <a
                        @click="
                          lookDetail(
                            'LFile/nba_content/MAO-B抑制剂在PD治疗中的优化应用-V3-20220725（加页码版）(1)_1663939782000.pdf'
                          )
                        "
                      >
                        MAO-B抑制剂在PD治疗中的优化应用-V3-20220725（加页码版）(v1.0)
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div v-if="physicianSelectedParting === 'C'">
                <div class="action-title">
                  <div class="title-flag"></div>
                  <span class="title-text">推进方向</span>
                </div>
                <div class="action-plan">
                  <div>
                    <p class="action-highlight">
                      <span>LD首选添加安齐来</span>
                    </p>
                  </div>
                </div>

                <div class="action-title">
                  <div class="title-flag"></div>
                  <span class="title-text">差异化竞争</span>
                </div>
                <div class="action-plan">
                  <div>
                    <p class="action-highlight">
                      <span>区隔DA—长期获益</span>
                    </p>
                  </div>
                </div>
                <div class="action-title">
                  <div class="title-flag"></div>
                  <span class="title-text">观念强化</span>
                </div>
                <div class="action-plan">
                  <p class="action-text">
                    <span class="action-flag"></span>
                    长期获益的重要性（神经保护）
                  </p>
                  <p class="action-text">
                    <span class="action-flag"></span>
                    MAO-BI神经保护/疾病修饰优于DA
                  </p>
                  <p class="action-text">
                    <span class="action-flag"></span>
                    运动波动、安全性
                  </p>
                  <div v-if="false" class="proof-link">
                    <h4>相关资料</h4>
                    <p class="link">
                      <span class="action-flag"></span>
                      <a
                        @click="
                          lookDetail(
                            'LFile/nba_content/2020中国帕金森病治疗指南（第四版）更新解读-美化版2-0608-Final_1663939546000.pdf'
                          )
                        "
                      >
                        2020中国帕金森病治疗指南（第四版）更新解读-美化版2-0608-Final
                        (v1.0)
                      </a>
                    </p>
                    <p class="link">
                      <span class="action-flag"></span>
                      <a
                        @click="
                          lookDetail(
                            'LFile/nba_content/质控项目-帕金森病全程管理新进展_1663939841000.pdf'
                          )
                        "
                      >
                        质控项目-帕金森病全程管理新进展 (v1.0)
                      </a>
                    </p>
                    <p class="link">
                      <span class="action-flag"></span>
                      <a
                        @click="
                          lookDetail(
                            'LFile/nba_content/CMA-4. 基于CDS的运动波动管理-V1.0-20220526_1663939724000.pdf'
                          )
                        "
                      >
                        CMA-4. 基于CDS的运动波动管理-V1.0-20220526 (v1.0)
                      </a>
                    </p>
                    <p class="link">
                      <span class="action-flag"></span>
                      <a
                        @click="
                          lookDetail(
                            'LFile/nba_content/质控项目-帕金森病共病药物管理-关注多重用药风险_1663939826000.pdf'
                          )
                        "
                      >
                        质控项目-帕金森病共病药物管理-关注多重用药风险 (v1.0)
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div v-if="physicianSelectedParting === 'D'">
                <div class="action-title">
                  <div class="title-flag"></div>
                  <span class="title-text">推进方向</span>
                </div>
                <div class="action-plan">
                  <div>
                    <p class="action-highlight">
                      <span>LD首选添加安齐来</span>
                    </p>
                  </div>
                  <div>
                    <p class="action-highlight">
                      <span>LD + DA + 安齐来</span>
                    </p>
                  </div>
                </div>

                <div class="action-title">
                  <div class="title-flag"></div>
                  <span class="title-text">差异化竞争</span>
                </div>
                <div class="action-plan">
                  <div>
                    <p class="action-highlight">
                      <span>区隔DA—症状</span>
                    </p>
                  </div>
                </div>
                <div class="action-title">
                  <div class="title-flag"></div>
                  <span class="title-text">观念强化</span>
                </div>
                <div class="action-plan">
                  <p class="action-text">
                    <span class="action-flag"></span>
                    步态障碍控制优选MAO-BI
                  </p>
                  <p class="action-text">
                    <span class="action-flag"></span>
                    运动波动、安全性
                  </p>
                  <p class="action-text">
                    <span class="action-flag"></span>
                    传递长期获益重要性（神经保护）
                  </p>
                  <div v-if="false" class="proof-link">
                    <h4>相关资料</h4>
                    <p class="link">
                      <span class="action-flag"></span>
                      <a
                        @click="
                          lookDetail(
                            'LFile/nba_content/灵北-雷沙吉兰-帕金森步态障碍管理初稿-9.14  -  Repaired_1663939805000.pdf'
                          )
                        "
                      >
                        灵北-雷沙吉兰-帕金森步态障碍管理初稿-9.14 - Repaired
                        (v1.0)
                      </a>
                    </p>
                    <p class="link">
                      <span class="action-flag"></span>
                      <a
                        @click="
                          lookDetail(
                            'LFile/nba_content/CMA-3.帕金森病运动波动的临床识别与管理-V1.0-20220526_1663939688000.pdf'
                          )
                        "
                      >
                        CMA-3.帕金森病运动波动的临床识别与管理-V1.0-20220526
                        (v1.0)
                      </a>
                    </p>
                    <p class="link">
                      <span class="action-flag"></span>
                      <a
                        @click="
                          lookDetail(
                            'LFile/nba_content/CMA-4. 基于CDS的运动波动管理-V1.0-20220526_1663939724000.pdf'
                          )
                        "
                      >
                        CMA-4. 基于CDS的运动波动管理-V1.0-20220526 (v1.0)
                      </a>
                    </p>
                    <p class="link">
                      <span class="action-flag"></span>
                      <a
                        @click="
                          lookDetail(
                            'LFile/nba_content/2020中国帕金森病治疗指南（第四版）更新解读-美化版2-0608-Final_1663939546000.pdf'
                          )
                        "
                      >
                        2020中国帕金森病治疗指南（第四版）更新解读-美化版2-0608-Final
                        (v1.0)
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div style="margin-top: 10px; margin-bottom: 20px;">
              <Button
                round
                type="primary"
                icon="todo-list"
                style="height: 38px"
                @click.stop="enterVisit()"
              >
                计划拜访
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <van-dialog
        v-model="physicianDialogShow"
        title="医生分型问答"
        theme="round-button"
        confirm-button-color="#0062b9"
        :before-close="beforePhysicianClose"
        @confirm="confirmPhysicianAnswer()"
      >
        <div class="diglog-close" @click="closeDiglog()">×</div>
        <div class="diglog-questions">
          <div class="question">
            <p class="question-text">
              1. 目标医生是否认为对于帕金森患者的治疗，<span class="highlight">长期获益</span>是否是处方决策的重要因素?
              <span style="color: #cc4b47">*</span>
            </p>
            <van-radio-group
              v-model="questionAnswers.firstAnswer"
              direction="horizontal"
              class="answer"
            >
              <van-radio name="true" style="margin-right: 30%">是</van-radio>
              <van-radio
                name="false"
                @click="
                  () => {
                    questionAnswers.secondAnswer = null;
                    questionAnswers.thirdAnswer = null;
                  }
                "
              >
                否
              </van-radio>
            </van-radio-group>
          </div>
          <div v-if="questionAnswers.firstAnswer == 'true'" class="question">
            <p class="question-text">
              2. 目前医生MAO-BI和DA的情况大致如何？
              <span style="color: #cc4b47">*</span>
            </p>
            <van-radio-group v-model="questionAnswers.secondAnswer">
              <van-radio name="true">
                DA : MAOBI 份额 ≤ 1.5 : 1
              </van-radio>
              <van-radio
                name="false"
                @click="
                  () => {
                    questionAnswers.thirdAnswer = null;
                  }
                "
              >
              DA : MAOBI 份额 > 1.5 : 1
              </van-radio>
            </van-radio-group>
          </div>
          <div v-if="questionAnswers.secondAnswer == 'true'" class="question">
            <p class="question-text">
              3. 医生安齐来的患者份额是否超过30%？
              <span style="color: #cc4b47">*</span>
            </p>
            <van-radio-group
              v-model="questionAnswers.thirdAnswer"
              direction="horizontal"
              class="answer"
            >
              <van-radio name="true" style="margin-right: 30%">是</van-radio>
              <van-radio name="false">否</van-radio>
            </van-radio-group>
          </div>
          <p v-if="physicianAnswerTips" style="color: #cc4b47">
            这是必答题，请选择答案！
          </p>
        </div>
      </van-dialog>
      <van-dialog
        v-model="partingShow"
        title="观念区隔"
        theme="round-button"
        confirm-button-color="#0062b9"
        @confirm="
          () => {
            partingShow = false;
          }
        "
      >
        <div style="padding: 0 20px 20px 20px">
          <div class="diglog-close" @click="closeDiglog()">×</div>
          <div v-if="showType === 'A'" class="parting-type-action">
            <div class="action-progress">
              <p>长期获益 - 倡导</p>
            </div>
            <p class="action-text">
              <span class="action-flag"></span>
              长期获益是其处方决策的重要因素
            </p>
            <p class="action-text">
              <span class="action-flag"></span>
              DA : MAOBI ≤1.5 : 1
            </p>
            <p class="action-text">
              <span class="action-flag"></span>
              安齐来的患者份额>30%
            </p>
          </div>
          <div v-if="showType === 'B'" class="parting-type-action">
            <div class="action-progress">
              <p>长期获益 - 认可</p>
            </div>
            <p class="action-text">
              <span class="action-flag"></span>
              长期获益是其处方决策的重要因素
            </p>
            <p class="action-text">
              <span class="action-flag"></span>
              DA : MAOBI ≤ 1.5 : 1
            </p>
            <p class="action-text">
              <span class="action-flag"></span>
              认为MAO-BI在神经保护/疾病修饰等方面优于DA
            </p>
          </div>
          <div v-if="showType === 'C'" class="parting-type-action">
            <div class="action-progress">
              <p>长期获益 - 尝试</p>
            </div>
            <p class="action-text">
              <span class="action-flag"></span>
              长期获益是其处方决策的重要因素
            </p>
            <p class="action-text">
              <span class="action-flag"></span>
              DA : MAOBI > 1.5
            </p>
            <p class="action-text">
              <span class="action-flag"></span>
              认为DA在神经保护/疾病修饰等方面和MAO-BI相当
            </p>
          </div>
          <div v-if="showType === 'D'" class="parting-type-action">
            <div class="action-progress">
              <p>症状控制 - 初识</p>
            </div>
            <p class="action-text">
              <span class="action-flag"></span>
              认为尽快控制症状更重要
            </p>
            <p class="action-text">
              <span class="action-flag"></span>
              DA 份额 : MAOBI 份额> 1.5 :1
            </p>
          </div>
        </div>
      </van-dialog>
    </div>
    <swipeDialog v-if="detailStatus" title="详情" @closeFn="closeFn">
      <van-loading v-if="fileLoading" class="loading">加载中...</van-loading>
      <pdfComponent v-if="pdfLink" :url="pdfLink" :pdf-key="pdfKey" />
      <!-- <van-loading v-if="fileLoading" class="loading">加载中...</van-loading>
      <div v-if="!fileLoading">
        <a :href="pdfLink" download>
          <img class="download" src="@/assets/nba/down.png" width="20" alt />
        </a>
        <pdf v-for="i in numPages" :key="i" :src="pdfLink" :page="i" />
      </div> -->
    </swipeDialog>
  </div>
</template>

<script>
import CommonHeader from "@/components/CommonHeader";
import { Button, RadioGroup, Radio, Loading, Popover } from "vant";
import * as py from "@/api/Physicians";
import { getLocalStorage } from "@/utils";
import * as moment from "moment";
import pdf from "vue-pdf";
import swipeDialog from "@/views/layout/swipeDialog";
import { s3Service } from "@/utils/aws/index";
import pdfComponent from "../pdfComponent.vue";
import { getMimeTypeBySuffix } from "@/utils/index";
export default {
  name: "Azilect",
  components: {
    CommonHeader,
    Button,
    vanRadioGroup: RadioGroup,
    vanRadio: Radio,
    swipeDialog,
    pdfComponent,
    vanLoading: Loading,
    vanPopover: Popover,
    // pdf,
  },
  props: {
    productId: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      loading: false,
      isPhysicianFirst: true,
      physicianDialogShow: false,
      questionAnswers: {
        firstAnswer: null,
        secondAnswer: null,
        thirdAnswer: null,
      },
      physicianPartings: [],
      showPhysicianPartingHistorys: [],
      showExpand: false,
      physicianHistoryExpandStatus: false,
      physicianAnswerTips: false,
      physicianSelectedParting: "",
      partingShow: false,
      star: require("@/assets/lundbeck/star.png"),
      showType: "",
      numPages: 0,
      pdfLink: "",
      pdfKey: "",
      detailStatus: false,
      fileLoading: false,
      canEditStatus: false,
      nbaRejectDialogShow: false,
      rejectReasonTemp: null,
      rejectReason: null,
      rejectReasonOther: '',
      rejectReasonInvalid: false,
      nbaExecutionId: '',
    };
  },
  mounted() {
    if (getLocalStorage('territory_type') === 'MR') {
      this.canEditStatus = true;
    }
    this.getPhysicianTyping();
  },
  methods: {
    getPhysicianTyping() {
      this.loading = true;
      let params = {
        physician_id: this.$route.query.physician_id,
        product_id: this.productId,
        territory_id: getLocalStorage("territory_id"),
      };
      py.getPhysicianTyping(params).then((res) => {
        if (res && res.value.length) {
          this.physicianPartings = res.value;

          if (this.physicianPartings.length > 0) {
            this.isPhysicianFirst = false;
            this.questionAnswers = JSON.parse(
              this.physicianPartings[0].content
            );
            if (
              !this.physicianHistoryExpandStatus &&
              this.physicianPartings.length > 2
            ) {
              this.showPhysicianPartingHistorys = this.physicianPartings.slice(
                0,
                2
              );
              this.showExpand = true;
            } else {
              this.showPhysicianPartingHistorys = this.physicianPartings;
              this.showExpand = false;
            }
            this.getPhysicianPartingResult();
          } else {
            this.isPhysicianFirst = true;
          }
        }
        this.loading = false;
      });
    },
    getPhysicianPartingResult() {
      let result = "";
      if (this.questionAnswers.firstAnswer === "true") {
        if (this.questionAnswers.secondAnswer === "true") {
          if (this.questionAnswers.thirdAnswer === "true") {
            this.physicianSelectedParting = "A";
            result = "长期获益 - 一线";
          } else if (this.questionAnswers.thirdAnswer === "false") {
            this.physicianSelectedParting = "B";
            result = "长期获益 - 认可";
          }
        } else if (this.questionAnswers.secondAnswer === "false") {
          this.physicianSelectedParting = "C";
          result = "长期获益 - 尝试";
        }
      } else if (this.questionAnswers.firstAnswer === "false") {
        this.physicianSelectedParting = "D";
        result = "症状控制 - 初识";
      }
      return result;
    },
    openPhysicianDialog() {
      this.physicianAnswerTips = false;
      if (this.isPhysicianFirst) {
        this.questionAnswers = {
          firstAnswer: null,
          secondAnswer: null,
          thirdAnswer: null,
        };
      }
      if (this.physicianPartings.length > 0) {
        this.questionAnswers = JSON.parse(
          this.physicianPartings[0].content
        );
      }
      this.physicianDialogShow = true;
    },
    beforePhysicianClose(action, done) {
      if (action === "confirm" && this.physicianDialogShow) {
        done(false);
      } else {
        done();
      }
    },
    closeDiglog() {
      this.partingShow = false;
      this.physicianDialogShow = false;
    },
    confirmPhysicianAnswer() {
      if (
        this.questionAnswers.firstAnswer == null ||
        (this.questionAnswers.firstAnswer === "true" &&
          this.questionAnswers.secondAnswer == null) ||
        (this.questionAnswers.firstAnswer === "true" &&
          this.questionAnswers.secondAnswer === "true" &&
          this.questionAnswers.thirdAnswer == null)
      ) {
        this.physicianAnswerTips = true;
        return;
      }
      let uName = getLocalStorage("user_name");
      let params = {
        physician_id: this.$route.query.physician_id,
        content: JSON.stringify(this.questionAnswers),
        user_id: getLocalStorage("user_id"),
        territory_id: getLocalStorage("territory_id"),
        create_time: Date.parse(new Date()),
        product_id: this.productId,
        result: this.getPhysicianPartingResult(),
        modify_user_id: getLocalStorage("user_id"),
        modify_user_name: uName !== 'null' ? uName : getLocalStorage("user_id"),
      };
      py.setPhysicianTyping(params).then((res) => {
        if (res.is_success) {
          this.$toast.success("提交成功");
          this.hospitalDialogShow = false;
          setTimeout(() => {
            this.getPhysicianTyping();
          }, 2);
        }
      });
      this.physicianDialogShow = false;
    },
    getDate(date) {
      return moment(new Date(+date)).format("YYYY-MM-DD");
    },
    expendPhysicianHistory() {
      if (
        this.physicianHistoryExpandStatus &&
        this.physicianPartings.length > 2
      ) {
        this.showPhysicianPartingHistorys = this.physicianPartings.slice(0, 2);
      } else {
        this.showPhysicianPartingHistorys = this.physicianPartings;
      }
      this.physicianHistoryExpandStatus = !this.physicianHistoryExpandStatus;
    },
    openPartingState(parting) {
      this.showType = parting;
      this.partingShow = true;
    },
    openRejectDialog() {
      this.rejectReasonInvalid = false;
      this.rejectReasonTemp = null;
      this.rejectReasonOther = '';
      this.nbaRejectDialogShow = true;
    },
    async rejectCurrentNba() {
      this.rejectReasonInvalid = false;
      console.log(this.rejectReasonTemp);
      if (!this.rejectReasonTemp) {
        this.rejectReasonInvalid = true;
        return;
      }

      this.rejectReason = this.rejectReasonTemp;

      // if (this.rejectReasonTemp === '') {
      //   return;
      // }

      let uName = getLocalStorage('user_name');
      let params = {
        physician_id: this.$route.query.physician_id,
        execution_time: new Date().toISOString(),
        execution_user_id: getLocalStorage('user_id'),
        execution_user_name: uName !== 'null' ? uName : getLocalStorage('user_id'),
        execution_territory_id: getLocalStorage('territory_id'),
        is_reject: 1,
        reject_reason: this.rejectReason,
        // physician_ladder_nba_id: this.nbaData.physician_ladder_nba_id,
        product_id: 'Azilect_000001',
        // segment_index: this.nbaData.segment_index,
        // segment_name: this.nbaData.segment_name,
        // sub_segment: this.nbaData.sub_segment,
        // ladder_index: this.nbaData.ladder_index,
        // ladder_name: this.nbaData.ladder_name,
        // sub_ladder: this.nbaData.sub_ladder,
        // next_ladder_index: this.nbaData.next_ladder_index,
        // next_ladder_name: this.nbaData.next_ladder_name,
      };

      // Find coach
      if (this.rejectReason === 'ask-for-coaching') {
        const dmRes = await py.findUser({
          user_id: getLocalStorage('user_id'),
        });
        let manager_user_id = null;
        if (dmRes && dmRes.value && dmRes.value.length) {
          manager_user_id = dmRes.value[0].parent_user_id;
        }

        if (manager_user_id) {
          params.coach_user_id = manager_user_id;

          const physicianRes = await py.getPhysiciansDetail(this.$route.query.physician_id);
          if (physicianRes && physicianRes.value && physicianRes.value.length) {
            const physicianName = physicianRes.value[0].physician_name;
            const institutionName = physicianRes.value[0].institution_name;

            // Insert message
            let message = {
              user_id: manager_user_id,
              message_title: '[辅导拜访请求] ' + uName,
              message_content: `您的同事${uName}在拜访${institutionName ? institutionName + '的' : ''}专家${physicianName}时无法进展，向您提出辅导拜访请求。`,
              // message_content: `您的同事${uName}向您提出辅导拜访请求。点击<a href='${BASE_URL}/hcp/detail?physician_id=${this.$route.query.physician_id}&selectedLabel=perception&temp_user_id=${getLocalStorage('user_id')}'>此处</a>查看需要辅导拜访的客户详情。`,
              send_time: new Date().toISOString(),
              is_send: 0,
              send_failed_no: 0,
              is_read: 0,
            };
            await py.createMessage(message);
          }
        }
      }

      this.ajaxStatus = true;
      py.createNbaExecution(params).then((res) => {
        if (res.is_success) {
          this.$toast.success("提交成功");
          this.nbaRejectDialogShow = false;
          // this.nbaExecutionId = res.entity?.nba_execution_id;
        } else {
          this.$toast.success("提交失败，请重试");
        }
        this.ajaxStatus = false;
      });
    },
    async enterVisit() {
      let uName = getLocalStorage('user_name');
      let params = {
        physician_id: this.$route.query.physician_id,
        execution_time: new Date().toISOString(),
        execution_user_id: getLocalStorage('user_id'),
        execution_user_name: uName !== 'null' ? uName : getLocalStorage('user_id'),
        execution_territory_id: getLocalStorage('territory_id'),
        is_reject: 0,
        product_id: 'Azilect_000001',
        // physician_ladder_nba_id: this.nbaData.physician_ladder_nba_id,
        // product_id: this.nbaData.product_id,
        // segment_index: this.nbaData.segment_index,
        // segment_name: this.nbaData.segment_name,
        // sub_segment: this.nbaData.sub_segment,
        // ladder_index: this.nbaData.ladder_index,
        // ladder_name: this.nbaData.ladder_name,
        // sub_ladder: this.nbaData.sub_ladder,
        // next_ladder_index: this.nbaData.next_ladder_index,
        // next_ladder_name: this.nbaData.next_ladder_name,
      };

      this.nbaExecutionId = '';
      const res = await py.createNbaExecution(params);
      if (res.is_success) {
        this.nbaRejectDialogShow = false;
        this.nbaExecutionId = res.entity?.nba_execution_id;
      }
      this.$router.push({
        path: `/hcp/visit-create?physician_id=${this.$route.query.physician_id}&nba_id=${this.nbaExecutionId}&product_id=Azilect_000001`,
      });
    },
    lookDetail(key) {
      this.$store.dispatch("setDialogStatus", true);
      this.detailStatus = true;
      this.fileLoading = true;

      if (this.detailStatus) {
        this.getPictureUrl(key);
      }
    },
    closeFn() {
      this.$store.dispatch("setDialogStatus", false);
      this.detailStatus = false;
      this.pdfLink = null;
      this.pdfKey = null;
    },
    getPictureUrl(key) {
      py.getS3ByKey(key).then((res) => {
        let currentMimeType = getMimeTypeBySuffix(
          key.split(".").pop().toLowerCase()
        );
        let blob = new Blob([res.data], {
          type: currentMimeType,
        });
        this.pdfKey = key;
        this.pdfLink = window.URL.createObjectURL(blob);
        this.fileLoading = false;
        // if (this.pdfLink) {
        //   this.getNumPages(this.pdfLink);
        // }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.parting {
  .tip-text {
    font-size: 14px;
    font-family: Arial;
    font-weight: 400;
    color: #2c3034;
    opacity: 1;
    margin: 40px auto 20px;
  }
  .tip-button {
    width: 160px;
    height: 40px;
    background: #1b75bc;
    opacity: 1;
    border-radius: 500px;
    margin-bottom: 20px;
  }
  .diglog-close {
    position: absolute;
    font-size: 30px;
    top: 25px;
    right: 20px;
  }
  .diglog-questions {
    padding: 20px;
    .question {
      margin-bottom: 20px;
      .question-text {
        font-size: 14px;
        font-family: Arial;
        font-weight: 700;
        line-height: 1.5;
        color: #2c3034;
        opacity: 1;
        text-align: left;
        margin-bottom: 10px;
      }
      .answer {
        display: flex;
        justify-content: center;
      }
    }
    .highlight {
      font-weight: 700;
      color: #0062b9;
    }
  }
  .parting-type {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    .parting-type-unselected {
      width: 74px;
      height: 80px;
      background: linear-gradient(46deg, #e8f4ff 0%, #c4e3ff 100%);
      opacity: 1;
      border-radius: 4px;
      margin: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        width: 50px;
        line-height: 1.5;
        color: #0062b9;
      }
    }
    .parting-type-selected {
      display: flex;
      align-items: center;
      width: 74px;
      height: 100px;
      background: linear-gradient(46deg, #52acff 0%, #0d7cfd 100%);
      opacity: 1;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        width: 50px;
        line-height: 1.5;
        color: #ffffff;
      }
    }
  }
  .parting-type-x {
    display: flex;
    justify-content: space-around;
    color: #838a95;
    p {
      display: inline-block;
    }
  }

  .title-flag {
    width: 6px;
    height: 14px;
    background: #1b75bc;
    opacity: 1;
    border-radius: 4px;
  }
  .title-text {
    height: 22px;
    font-size: 16px;
    font-family: Arial;
    font-weight: 700;
    color: #2c3034;
    opacity: 1;
    margin-left: 5px;
  }
  .parting-type-action {
    margin-top: 30px;
    .action-title {
      display: flex;
      align-items: baseline;
      height: 20px;
    }
    .action-plan {
      padding: 15px 10px;
    }
    .action-flag {
      display: inline-block;
      width: 6px;
      height: 6px;
      background: #0062b9;
      opacity: 1;
      border-radius: 4px;
      margin: 0 5px 2px 0;
    }
    .action-text {
      font-size: 14px;
      font-family: Arial;
      font-weight: 400;
      color: #2c3034;
      opacity: 1;
      text-align: left;
      line-height: 1.3;
      margin-bottom: 15px;
    }
    .key-message-labels > span {
      background: #d0e9ff;
      border-radius: 20px;
      font-size: 11px;
      color: #333;
      white-space: nowrap;
      padding: 3px 8px;
      margin: 0 2px 0 0;
    }
    .action-highlight {
      font-size: 16px;
      font-family: Arial;
      font-weight: 700;
      color: #0062b9;
      opacity: 1;
      text-align: left;
      line-height: 1.4;
      margin-bottom: 15px;
      .action-flag {
        background: #aaa;
      }
      span {
        font-weight: 700;
      }
    }
    .action-tag {
      color: #3caa45;
    }
    .highlight {
      color: #0062b9;
    }
    .action-progress {
      display: flex;
      align-items: center;
      height: 30px;
      background: rgba(0, 98, 185, 0.08);
      border-radius: 4px;
      margin-bottom: 20px;
      p {
        color: #0062b9;
        font-size: 14px;
        font-family: Arial;
        font-weight: 700;
        margin-left: 10px;
      }
    }
  }
  .download {
    z-index: 1;
    position: absolute;
    top: 450px;
    right: 20px;
    border: 1px solid rgb(218, 217, 217);
    border-radius: 100%;
    padding: 5px;
  }
  .proof-link {
    h4 {
      font-size: 14px;
      font-family: Arial;
      font-weight: bold;
      color: #2c3034;
      margin: 10px 0 20px;
      padding: 0;
    }
  }
}
.loading {
  margin-top: 55%;
}
.reject-box {
  width: 50vw;
  padding: 15px;

  .reject-prompt {
    font-size: 14px;
    color: #666;
  }
  .answer {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 10px;
    ::v-deep .van-checkbox__label,
    ::v-deep .van-radio__label {
      font-size: 14px;
      text-align: left;
      margin: 4px;
    }
  }
  .reject-button {
    width: 150px;
    height: 36px;
    opacity: 1;
    border-radius: 500px;
    margin: 20px auto;
    display: block
  }
  .textarea {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 15px;
    color: #666;
  }
}
</style>
